import Glide from '@glidejs/glide';

function init() {
  new Glide('.glide').mount();
}

export default function () {
  if (document.querySelector('.glide')) {
    init();
  }
}
