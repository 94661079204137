import jump from 'jump.js'

function scrollTo(event) {
  event.preventDefault();

  const newsletter = document.querySelector('#subscribe');
  const newsletterInput = newsletter.querySelector('input');

  // Scroll to newsletter
  jump(newsletter);

  // Focus input
  newsletterInput.focus();
}

function bind() {
  const subscribeButton = document.querySelector('.js-nav-subscribe');

  subscribeButton.addEventListener('click', scrollTo);
}

export default function () {
  if (document.querySelector('.newsletter')) {
    bind();
  }
}
