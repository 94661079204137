import jQuery from 'jquery';
import Isotope from 'isotope-layout';
import ImagesLoaded from 'imagesloaded';
window.jQuery = jQuery;

let iso;
let loadMoreButton = document.querySelector('.js-load-more');

function enableButton(button) {
  button.innerHTML = 'Load More';
  button.disabled = false;
}

function disableButton(button) {
  button.innerHTML = 'Loading...';
  button.disabled = true;
}

function showButton(button) {
  button.style.opacity = '1';
}

function hideButton(button) {
  button.style.opacity = '0';
}

function addActiveClass(element) {
  element.classList.add('tag--active');
}

function removeActiveClass() {
  const filters = document.querySelectorAll('.filter__item');

  [...filters].forEach(filter => filter.querySelector('.tag').classList.remove('tag--active'));
}

function handleLoadMore(event) {
  const button = event.target;
  const totalPages = button.dataset.total;
  let page = button.dataset.page;
  let category = button.dataset.category;

  if (Number(page) <= Number(totalPages)) {
    disableButton(button);

    jQuery.ajax({
      url : '/wp-admin/admin-ajax.php',
      data : {
        'action': 'load_more',
        'page_number': page,
        'category': category
      },
      type : 'POST',
      success : function (data) {
        const cards = jQuery(data);
        const updatedPage = Number(page) + 1;

        if (updatedPage > totalPages) {
          hideButton(button);
          enableButton(button);
        } else {
          button.dataset.page = updatedPage;
          enableButton(button);
        }

        iso.insert(cards);
      }
    });
  }
}

function handleFilter(event) {
  const filter = event.target;
  const filterCategory = filter.getAttribute('data-filter');
  const filterPageCount = Math.ceil(filter.getAttribute('data-count') / 12);

  // Update the data attributes for the load more button with the new category's values
  loadMoreButton.dataset.category = filterCategory ? filterCategory : '';
  loadMoreButton.dataset.total = filterPageCount ? filterPageCount : '';
  loadMoreButton.dataset.page = 2;

  removeActiveClass();
  addActiveClass(filter);

  jQuery.ajax({
    url : '/wp-admin/admin-ajax.php',
    data : {
      'action': 'load_more',
      'page_number': 1,
      'category': filterCategory
    },
    type : 'POST',
    success : function (data) {
      const cards = jQuery(data);

      if (loadMoreButton.dataset.page > filterPageCount) {
        hideButton(loadMoreButton);
      } else {
        showButton(loadMoreButton);
      }

      iso.remove(jQuery('.card'));
      iso.insert(cards);
    }
  });
}

function bind() {
  const grid = document.querySelector('.grid');
  const filters = document.querySelectorAll('.filter__item');

  [...filters].forEach(filter => filter.querySelector('.tag').addEventListener('click', handleFilter));

  ImagesLoaded(grid, function(instance) {
    iso = new Isotope(grid, {
      itemSelector: '.card',
      layoutMode: 'fitRows'
    });
  });

  loadMoreButton.addEventListener('click', handleLoadMore);
}

export default function() {
  if (document.querySelector('.posts')) {
    bind();
  }
}
