import React from 'react'
import PropTypes from 'prop-types'

import {
  highsnobiety,
  gq,
  complex,
  proper
} from './images'

class FeatureBar extends React.Component {
  images () {
    const images = [
      [ "Highsnobiety", highsnobiety ],
      [ "GQ", gq ],
      [ "Complex", complex ],
      [ "Proper", proper ]
    ]
    return images.map((image, i) => (
      <img
        className="feature-bar__logo"
        src={"/wp-content/themes/laced/dist/assets/" + image[1]}
        alt={image[0]}
        key={`feature-bar__logo-${i}`}
      />
    ))
  }

  render() {
    return (
      <div className="feature-bar">
        <div className="feature-bar__inner">
          {this.images()}
        </div>
      </div>
    )
  }
}

export default FeatureBar
