import React from 'react'
import PropTypes from 'prop-types'

class FooterAccordion extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      expanded: false
    }

    this.handleAccordionToggle = this.handleAccordionToggle.bind(this)
  }

  handleAccordionToggle() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { expanded } = this.state
    const { item } = this.props
    let accordionClasses = ['ftr-nav', 'accordion-mobile']
    let accordionToggleClasses = ['ftr__title', 'accordion-mobile__toggle']
    let listClasses = ['ftr-nav__list']

    if (expanded) {
      accordionClasses.push('accordion-mobile--expanded')
    } else {
      accordionToggleClasses.push('marginless-mobile')
      listClasses.push('hide-mobile')
    }
    if (item.classes) accordionClasses = accordionClasses.concat(item.classes)

    return (
      <div className={accordionClasses.join(' ')}>
        <h4
          className={accordionToggleClasses.join(' ')}
          onClick={this.handleAccordionToggle}
        >
          {item.title}
        </h4>
        <ul className={listClasses.join(' ')}>
          {item.links.map((link, i) => (
            <li className="ftr-nav__item" key={`ftr-nav__item_${i}`}>
              <a className="ftr-nav__link" key={`ftr-nav__link_${i}`} {...link}/>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

FooterAccordion.propTypes = {
  item: PropTypes.object.isRequired
}

export default FooterAccordion
