import ReactDOM from 'react-dom';
import React from 'react';
import Footer from '../../templates/components/footer/Footer';
import carousel from '../../templates/components/carousel/carousel';
import nav from '../../templates/components/nav/nav';
import posts from '../../templates/components/posts/posts';

carousel();
nav();
posts();

const navs = [
  {
    title: 'Top Brands',
    links: [
      { children: 'Nike', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Nike' },
      { children: 'Adidas', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Adidas' },
      { children: 'Air Jordan', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Air+Jordan' },
      { children: 'Yeezy', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Yeezy' }
    ]
  },
  {
    title: 'Best Sellers',
    links: [
      { children: 'Jordan 3', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Jordan+3' },
      { children: 'Yeezy', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Yeezy' },
      { children: 'Jordan 12 Retro', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Jordan+12+Retro' },
      { children: 'Jordan 4', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Jordan+4' },
      { children: 'Superstar', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Superstar' },
      { children: 'Flyknit', href: 'https://www.laced.co.uk/search?search%5Bsort_by%5D=popularity&search%5Bterm%5D=Flyknit' }
    ]
  },
  {
    title: 'About Us',
    links: [
      { children: 'FAQ/Help', href: 'https://www.laced.co.uk/pages/faq' },
      { children: 'My Account', href: 'https://www.laced.co.uk/account' },
      { children: 'Terms & Conditions', href: 'https://www.laced.co.uk/pages/terms-and-conditions' }
    ]
  },
  {
    title: 'Social',
    links: [
      { children: 'Instagram', href: 'https://www.instagram.com/lacedltd/' },
      { children: 'Twitter', href: 'https://twitter.com/lacedltd' },
      { children: '+44 1303 850659', href: 'tel:+441303850659' },
      { children: 'help@laced.co.uk', href: 'mailto:help@laced.co.uk' }
    ],
    classes: ['hide-mobile']
  }
];

ReactDOM.render(<Footer navs={navs} />, document.getElementById('footer'));
