import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FeatureBar from "./FeatureBar.js";
import FooterAccordion from "./FooterAccordion.js";

import {
  logoSrc,
  instagram,
  twitter,
  phone,
  email,
  mastercard,
  visa,
  americanExpress,
  paypal
} from "./images";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.trustPilotRef = React.createRef();
  }

  componentDidMount() {
    window.Trustpilot &&
      window.Trustpilot.loadFromElement(this.trustPilotRef.current);
  }

  flex() {
    return (
      <div className="ftr__flex">
        <div className="social-nav">
          <h4 className="ftr__title">
            <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + logoSrc} alt="Laced" />
          </h4>
          <ul className="ftr-nav__list ftr__logos ftr__logos--small hide-desktop">
            <li className="ftr-nav__item" key={`ftr-nav__item_instagram`}>
              <a
                className="ftr-nav__link"
                href="https://www.instagram.com/lacedltd/"
              >
                <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + instagram} alt="Instagram" />
              </a>
            </li>
            <li className="ftr-nav__item" key={`ftr-nav__item_twitter`}>
              <a className="ftr-nav__link" href="https://twitter.com/lacedltd">
                <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + twitter} alt="Twitter" />
              </a>
            </li>
            <li className="ftr-nav__item" key={`ftr-nav__item_phone`}>
              <a className="ftr-nav__link" href="tel:+441303850659">
                <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + phone} alt="Phone" />
              </a>
            </li>
            <li className="ftr-nav__item" key={`ftr-nav__item_email`}>
              <a className="ftr-nav__link" href="mailto:help@laced.co.uk">
                <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + email} alt="Email" />
              </a>
            </li>
          </ul>
        </div>
        {this.props.navs.map((nav, key) => (
          <FooterAccordion key={key} item={nav} />
        ))}
      </div>
    );
  }

  footer() {
    return (
      <div className="ftr__footer">
        <div className="ftr__logos">
          <div className="ftr__logo--container">
            <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + mastercard} alt="Mastercard" />
          </div>
          <div className="ftr__logo--container">
            <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + visa} alt="Visa" />
          </div>
          <div className="ftr__logo--container">
            <img
              className="ftr__logo"
              src={"/wp-content/themes/laced/dist/assets/" + americanExpress}
              alt="American Express"
            />
          </div>
          <div className="ftr__logo--container">
            <img className="ftr__logo" src={"/wp-content/themes/laced/dist/assets/" + paypal} alt="PayPal" />
          </div>
        </div>
        <div className="ftr__footer__inner">
          <div className="ftr__copyright">
            <p>All Rights Reserved &copy; {new Date().getFullYear()} Laced</p>
            <p className="hide-mobile">&nbsp;|&nbsp;</p>
            <p>
              Floor 1 32-40 Tontine Street Folkestone CT20 1JU &bull; Company
              No. 09541333
            </p>
          </div>
          <div className="ftr__trustpilot">
            {/* TrustBox widget - Micro TrustScore */}
            <div
              className="trustpilot-widget"
              data-locale="en-GB"
              data-template-id="5419b637fa0340045cd0c936"
              data-businessunit-id="5c812ed9871dac0001ea1384"
              data-style-width="100%"
              data-style-height="60px"
              data-theme="dark"
              ref={this.trustPilotRef}
            >
              <a
                href="https://uk.trustpilot.com/review/laced.co.uk"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>{" "}
            </div>
            {/* End TrustBox widget */}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <FeatureBar />
        <footer className="ftr">
          <div className="ftr__inner">
            {this.flex()}
            {this.footer()}
          </div>
        </footer>
      </Fragment>
    );
  }
}

Footer.propTypes = {
  navs: PropTypes.array.isRequired
};

export default Footer;
